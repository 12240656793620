import './BlogPage.scss'
import React from 'react'
import Layout from "../../components/layout";
import SEO from "../../components/seo";

const BlogPage = () => (
    <Layout>
        <SEO title="Blog"/>
        Blog
    </Layout>
);

export default BlogPage
