import './Header.scss'
import React from 'react'

import {Navbar} from 'react-materialize';
import {Link} from 'gatsby';
import Search from "./Search";
import Logo from "../Logo";

const Header = () => (
    <Navbar
        className='Header'
        brand={<Logo className='Header__logo hide-on-med-and-down'/>}
        alignLinks="right" fixed
    >
        <Search/>
        {/* Add Active link support */}
        <Link className='Header__link hide-on-med-and-up' to="/">Home</Link>
        <Link className='Header__link' to="/about">About</Link>
        <Link className='Header__link' to="/blog">Blog</Link>
        <Link className='Header__link' to="/tutorials">Tutorials</Link>
        <Link className='Header__link' to="/courses">Courses</Link>
        <Link className='Header__link' to="/community">Community</Link>
    </Navbar>
);

export default Header
