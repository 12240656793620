// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-blog-template-js": () => import("../src/templates/blogTemplate.js" /* webpackChunkName: "component---src-templates-blog-template-js" */),
  "component---src-pages-404-index-js": () => import("../src/pages/404/index.js" /* webpackChunkName: "component---src-pages-404-index-js" */),
  "component---src-pages-about-creator-js": () => import("../src/pages/about/Creator.js" /* webpackChunkName: "component---src-pages-about-creator-js" */),
  "component---src-pages-about-future-js": () => import("../src/pages/about/Future.js" /* webpackChunkName: "component---src-pages-about-future-js" */),
  "component---src-pages-about-index-js": () => import("../src/pages/about/index.js" /* webpackChunkName: "component---src-pages-about-index-js" */),
  "component---src-pages-about-objectives-js": () => import("../src/pages/about/Objectives.js" /* webpackChunkName: "component---src-pages-about-objectives-js" */),
  "component---src-pages-blog-index-js": () => import("../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-community-index-js": () => import("../src/pages/community/index.js" /* webpackChunkName: "component---src-pages-community-index-js" */),
  "component---src-pages-courses-index-js": () => import("../src/pages/courses/index.js" /* webpackChunkName: "component---src-pages-courses-index-js" */),
  "component---src-pages-index-js": () => import("../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-page-2-index-js": () => import("../src/pages/page-2/index.js" /* webpackChunkName: "component---src-pages-page-2-index-js" */),
  "component---src-pages-tutorials-index-js": () => import("../src/pages/tutorials/index.js" /* webpackChunkName: "component---src-pages-tutorials-index-js" */)
}

