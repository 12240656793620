import './CommunityPage.scss'
import React from 'react'
import Layout from "../../components/layout";
import SEO from "../../components/seo";

const CommunityPage = () => (
    <Layout>
        <SEO title="Community"/>
        Community
    </Layout>
);

export default CommunityPage
