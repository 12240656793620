import youtubeLogo from "../../icons/youtube.svg";
import facebookLogo from "../../icons/facebook.svg";
import twitterLogo from "../../icons/twitter.svg";
import instagramLogo from "../../icons/instagram.svg";
import redditLogo from "../../icons/reddit.svg";
import patreonLogo from "../../icons/patreon.svg";
import gitterLogo from "../../icons/gitter.svg";
import githubLogo from "../../icons/github.svg";
import feedbackLogo from "../../icons/feedback.svg";
import React from "react";

const Links = () => (
    <ul className='Footer__links'>
        <li className='Footer__links__element'>
            <a target='_blank' rel="noopener noreferrer" href="https://www.youtube.com/c/GopherTuts">
                <img className='Footer__links__img' src={youtubeLogo} alt="youtube"/>
                <span className='Footer__links__caption'>GopherTuts</span>
            </a>
        </li>
        <li className='Footer__links__element'>
            <a target='_blank' rel="noopener noreferrer" href="https://www.facebook.com/steevehookmd">
                <img className='Footer__links__img' src={facebookLogo} alt="facebook"/>
                <span className='Footer__links__caption'>steevehookmd</span>
            </a>
        </li>
        <li className='Footer__links__element'>
            <a target='_blank' rel="noopener noreferrer" href="https://www.facebook.com/GopherTuts-168566280728164">
                <img className='Footer__links__img' src={facebookLogo} alt="facebook"/>
                <span className='Footer__links__caption'>GopherTuts</span>
            </a>
        </li>
        <li className='Footer__links__element'>
            <a target='_blank' rel="noopener noreferrer" href="https://twitter.com/@steevehook">
                <img className='Footer__links__img' src={twitterLogo} alt="twitter"/>
                <span className='Footer__links__caption'>@steevehook</span>
            </a>
        </li>
        <li className='Footer__links__element'>
            <a target='_blank' rel="noopener noreferrer" href="https://twitter.com/@GopherTuts">
                <img className='Footer__links__img' src={twitterLogo} alt="twitter"/>
                <span className='Footer__links__caption'>@GopherTuts</span>
            </a>
        </li>
        <li className='Footer__links__element'>
            <a target='_blank' rel="noopener noreferrer" href="https://instagram.com/steevehook/">
                <img className='Footer__links__img' src={instagramLogo} alt="instagram"/>
                <span className='Footer__links__caption'>@steevehook</span>
            </a>
        </li>
        <li className='Footer__links__element'>
            <a target='_blank' rel="noopener noreferrer" href="https://www.reddit.com/user/steevehook">
                <img className='Footer__links__img' src={redditLogo} alt="reddit"/>
                <span className='Footer__links__caption'>steevehook</span>
            </a>
        </li>
        <li className='Footer__links__element'>
            <a target='_blank' rel="noopener noreferrer" href="https://www.patreon.com/gophertuts">
                <img className='Footer__links__img' src={patreonLogo} alt="patreon"/>
                <span className='Footer__links__caption'>gophertuts</span>
            </a>
        </li>
        <li className='Footer__links__element'>
            <a target='_blank' rel="noopener noreferrer" href="https://gitter.im/GopherTuts">
                <img className='Footer__links__img' src={gitterLogo} alt="gitter"/>
                <span className='Footer__links__caption'>GopherTuts</span>
            </a>
        </li>
        <li className='Footer__links__element'>
            <a target='_blank' rel="noopener noreferrer" href="https://github.com/steevehook">
                <img className='Footer__links__img' src={githubLogo} alt="github"/>
                <span className='Footer__links__caption'>steevehook</span>
            </a>
        </li>
        <li className='Footer__links__element'>
            <a target='_blank' rel="noopener noreferrer" href="https://github.com/gophertuts">
                <img className='Footer__links__img' src={githubLogo} alt="github"/>
                <span className='Footer__links__caption'>gophertuts</span>
            </a>
        </li>
        <li className='Footer__links__element'>
            <a target='_blank' rel="noopener noreferrer" href="https://feedback.gophertuts.com">
                <img className='Footer__links__img' src={feedbackLogo} alt="feedback"/>
                <span className='Footer__links__caption'>Feedback</span>
            </a>
        </li>
    </ul>
);

export default Links
